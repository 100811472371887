import React from "react"
import { graphql } from "gatsby"
import PageTitle from "components/pageTitle"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"

const AgreementPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <div className="terms-of-card-page">
        <div className="contents">
          <PageTitle title="バルコカード" subTitle="" />
          {ReactHtmlParser(
            data.strapiTermsOfCardserviceTermsOfCardserviceTextnode
              ?.terms_of_cardservice ?? ""
          )}
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => <Seo title="バルコカード利用規約" description="" />
export default AgreementPage

export const query = graphql`
  query {
    strapiTermsOfCardserviceTermsOfCardserviceTextnode(
      terms_of_cardservice: {}
    ) {
      terms_of_cardservice
    }
  }
`
